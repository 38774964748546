import React, { Component, useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Fade } from 'react-reveal';
import { Navbar, Nav, Row, Col, Container } from 'react-bootstrap';
import {
    Link,
    withRouter
} from "react-router-dom";

import Logo_Hero from './image/token_collaborative_logo.png';
import Logo_Main from './image/token_collab.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import Get_Touch from './image/get-touch.png';
import { Link as LinkSmooth, animateScroll as scroll } from "react-scroll";

function Contact() {
    const inputFullname = useRef();
    const inputEmail = useRef();
    const inputPhone = useRef();
    const inputComments = useRef();

    useEffect(()=>{
        scrollToTop();
    },[])

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    const sendEmail = () => {
        setDisabled(true)
        fetch('/sendEmail.php', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify(form)
        })
        .then(resp => resp.json())
        .then(function(response) {
            console.log(response)
            if(response.success) {
                setError(false)
                setSuccess(response.message)
                inputFullname.current.value = "";
                inputEmail.current.value = "";
                inputPhone.current.value = "";
                inputComments.current.value = "";
            } else {
                setSuccess(false)
                setError(response.message)
            }
            setDisabled(false)
        }).catch(e=>{
            console.log(e)
            setDisabled(false)
            setSuccess(false)
            setError("Error. Try later.")
        });
    }

    const [form, setForm] = useState({})
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)

    return (
        <>

            <Navbar bg="white" fixed="top" className="align-items-center navbar-top" expand="lg">
                <Navbar.Brand href="#home">
                    <Link to="/">
                        <img src={Logo_Main} alt="Logo" className="logo-img" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
                    <Nav className="justify-content-end">
                        <Link className="nav-link" to="/#vision">Our Vision</Link>
                        <Link className="nav-link" to="/#whats-are">What are NFT's</Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Container style={{ paddingTop: 200, paddingBottom: 200 }}>
                <Row>
                    <Col lg={6} className="my-auto">
                        <p className="get-in-touch">
                            Get in touch
                        </p>
                        <Row>
                            <Col>
                                <div className="floating-label">
                                    <input ref={inputFullname} className="floating-input" type="text" placeholder=" " onChange={(e)=>setForm({...form, fullname: e.target.value})} />
                                    <label>Full Name</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="floating-label">
                                    <input ref={inputEmail} className="floating-input" type="email" placeholder=" " onChange={(e)=>setForm({...form, email: e.target.value})} />
                                    <label>Email</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="floating-label">
                                    <input ref={inputPhone} className="floating-input" type="text" placeholder=" "  onChange={(e)=>setForm({...form, phone: e.target.value})}/>
                                    <label>Phone</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="floating-label">
                                    <textarea ref={inputComments} className="floating-input floating-textarea" placeholder=" " rows="3" onChange={(e)=>setForm({...form, comments: e.target.value})}></textarea>
                                    <label>Comments</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    error ? <p className="erroMsg">{error}</p> : null
                                }
                                {
                                    success ? <p className="successMsg">{success}</p> : null
                                }
                                {
                                    !disabled ? <Button variant="red" onClick={sendEmail}>Send</Button> : <Button variant="gray">Send</Button>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className="text-right">
                        <Fade duration={1200}>
                            <img src={Get_Touch} className="img-fluid mt-5 mt-lg-0" alt="Touch" 
                                style={{
                                    filter: "hue-rotate(113deg)"
                                }}
                            />
                        </Fade>
                    </Col>
                </Row>
            </Container>

            <section id="footer-container">
                <Container >
                    <Row className="footer-text  align-items-center">
                        <Col md={4} className="text-center text-lg-left">
                            <img src={Logo_Hero} className="logo-footer" />
                        </Col>
                        <Col md={8} className="text-center text-md-right">
                            <ul className="footer-links">
                                <li><Link to="/#vision">Our Vision</Link></li>
                                <li>-</li>
                                <li><Link to="/#whats-are">What are NFT's</Link></li>
                                <li>-</li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li>-</li>
                                <li><Link to="/">Privacy &amp; Policy</Link></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row><Col><p className="text-center d-block footer-text mb-0">Copyright © TokenCollab 2021</p></Col></Row>
                </Container>
            </section>
        </>
    );

}



export default withRouter(Contact);
