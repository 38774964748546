import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Home from './Home';
import Contact from './Contact';

const App = () => {
    return (
        <Router>
            <Switch>
                
                <Route path="/contact-us">
                    <Contact />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    )
}

export default App