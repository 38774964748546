import React, { Component, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import Button from 'react-bootstrap/Button';
import { Navbar, Nav, Row, Col, Container } from 'react-bootstrap';
import { Link as LinkSmooth, scroller as scroll, animateScroll} from "react-scroll";
import {
  Link,
  withRouter
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';

import Logo_Main from './image/token_collab.png';
import Logo_Hero from './image/token_collaborative_logo.png';
import Production from './image/production.gif';
import Discover from './image/discover.png';
import WhatsAreNft from './image/whats-are-nft.png';

import cityVideo from "./video/city-video.mp4";


function Home() {
  const [heroMove, setHeroMove] = useState({
    top: 0,
    left: 0
  });
  const handleMouseEnter = (e) => {
    setHeroMove({ left: e.clientX, top: e.clientY })
  }
  const handleMouseMove = (e) => {
    setHeroMove({ left: e.nativeEvent.clientX, top: e.nativeEvent.clientY })
  }
  const handleMouseLeave = (e) => {
    setHeroMove({ left: 0, top: 0 })
  }

  useEffect(()=>{
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    target = target.split("#")[1]
    if(target){
      scroll.scrollTo(target, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -150
      });
    }else{
      animateScroll.scrollToTop();
    }
    
  },[])

  return (
    <>
      <Navbar bg="white" fixed="top" className="align-items-center navbar-top" expand="lg">
        <Navbar.Brand href="#home">
          <Link to="/" onClick={animateScroll.scrollToTop}>
            <img src={Logo_Main} alt="Logo" className="logo-img" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="justify-content-end">
            <LinkSmooth className="nav-link" to="vision" offset={-150} spy={true} smooth={true} duration={500}>Our Vision</LinkSmooth>
            <LinkSmooth className="nav-link" to="whats-are" offset={-150} spy={true} smooth={true} duration={500}>What are NFT's</LinkSmooth>
            <Link to="/contact-us" style={{textAlign: 'center'}}><Button variant="red" size="lg">Contact Us</Button></Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>


      <div id="home"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >

        <video autoplay="autoplay" loop="loop" muted className="videoCityBg">
          <source src={cityVideo} type="video/mp4" />
            Your browser not support the video tag.
        </video>
        <div className="videoBg"></div>

        <div className="banner-img">
          <div
            style={{
              position: "relative"
            }}
          >
            <img src={Logo_Hero} alt="Logo" className="logoHero" />
            <p className="heroText">An NFT Workspace - Where Creative Dreams Become Reality</p>
          </div>

        </div>

      </div>
      <Container className="first-content" id="vision">
        <Row className="justify-content-center align-items-center">
          <Col sm md={6}>
            <Fade duration={1000}>
              <p className="first-title">
                Our Vision
              </p>
              <p className="paragraph">Token Collaborative aims to provide a collaborative virtual studio space and agency for artists and content creators to bring their NFT concepts to life. </p>
              <p className="paragraph">Our mission is to bring relationships to the forefront of our service. Token Collaborative will streamline all the elements of the NFT process, ranging from the start of the conceptual creation, to the digitalized tokenization. We assist all of our artists, celebrities and technologists through to the distribution process and place all of these aspects into an easy one stop package.</p>

              <Link to="/contact-us"><Button className="mt-3" variant="red">Learn more</Button></Link>
            </Fade>
          </Col>
          <Col sm md={6}>
            <Fade duration={1500} delay={300}>
              <img src={Production} alt="Make it unique" className="img-fluid" />
            </Fade>
            {/*<video id="video" className="video-production" src="image/production.mp4" muted loop autoPlay playsInline /> */}
          </Col>
        </Row>
      </Container>

      {/*<Container>
        <Row className="justify-content-md-center" id="vision"  style={{paddingTop: 100, paddingBottom: 100}}>
          <Col sm md={6}>
            <Fade duration={1500} delay={300}>
              <Row>
                <Col className="about-us">Our Vision</Col>
              </Row>
              <Row>
                <Col>
                  <p className="paragraph">Token Collaborative aims to provide a collaborative virtual studio space and agency for artists and content creators to bring their NFT concepts to life. </p>
                  <p className="paragraph">Our mission is to bring relationships to the forefront of our service.  Token Collaborative will streamline all elements of the NFT spaces process, ranging from the start of the conceptual creation, to the digitalized tokenization and all the way through to the distribution process for artists, celebrities and technologists and form all these aspects into an easy one stop package.</p>
                </Col>
              </Row>
              <Row >
                <Col>
                  <Button variant="red">Learn more</Button>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col sm md={6} className="text-right">
            <Fade duration={1000}>
              <img src={About} className="img-fluid mt-5 mt-lg-0" alt="About" />
            </Fade>
          </Col>
        </Row>
      </Container>*/}

      <Container className="first-content" id="whats-are">
        <Row className="mb-4">
          <Col>
            <Fade duration={1500}>
              <p className="first-title">What are NFT's</p>
              <p className="paragraph">An NFT (Non-fungible Token) is an unique representation of an asset or good in the form of a virtual token. Ownership of the token directly reflects the ownership of the asset or good and is immutably stored on the blockchain and it can be sold or traded.</p>
              </Fade>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Fade duration={1500} delay={500}>
              <img src={WhatsAreNft} className="whats-are-nft-img" />
            </Fade>
          </Col>
        </Row>
      </Container>
      <Container id="discover" className="first-content">
        <Fade duration={1500}>
          <Row className="align-items-center">
            <Col lg={6}>
              <img src={Discover} className="img-fluid mb-5 mb-lg-0" alt="Discover" />
            </Col>
            <Col lg={6}>
              <p className="first-title">Collaborative</p>
              <p className="paragraph">Interested in creating unique NFT content?</p>
              <Link to="/contact-us"><Button className="mt-3" variant="red">Get in Touch</Button></Link>
            </Col>
          </Row>
        </Fade>
      </Container>
      <section id="footer-container">
        <Container >
          <Row className="footer-text align-items-center">
            <Col md={4} className="text-center text-lg-left">
              <img src={Logo_Hero} className="logo-footer" />
            </Col>
            <Col md={8} className="text-center text-md-right">
              <ul className="footer-links">
                <li><LinkSmooth to="vision" offset={-150} spy={true} smooth={true} duration={500}>Our Vision</LinkSmooth></li>
                <li>-</li>
                <li><LinkSmooth to="whats-are" offset={-150} spy={true} smooth={true} duration={500}>What are NFT's</LinkSmooth></li>
                <li>-</li>
                <li><Link to="/contact-us">Contact Us</Link></li>
                <li>-</li>
                <li><Link to="/">Privacy &amp; Policy</Link></li>
              </ul>
            </Col>
          </Row>
          <hr />
          <Row><Col><p className="text-center d-block footer-text mb-0">Copyright © TokenCollab 2021</p></Col></Row>
        </Container>
      </section>
    </>
  );

}



export default withRouter(Home);
